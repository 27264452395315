export const env = {
  name: "PRODUCTION",
  blockchainScanSite: 'https://polygonscan.com/tx/',
  errorRedirectUrl: 'https://unblended.it',
  updatePasswordUrl: 'https://id.unblended.it/user/updatePasswordAuthenticated',
  resetPasswordUrl: 'https://id.unblended.it/user/%userId%/resetPassword',
  appId: '6399a4089238892f157dffad',
  TERMS_LINK: 'https://unblended.it/it/terms.html',
  allowedBEDomains: ['be.unblended.it', 'be-stage.unblended.it', 'be-sandbox.unblended.it'],
}
